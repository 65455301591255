section.intro {
    margin-top:2rem;
	background-image:url(../images/about.jpg);
	background-repeat:no-repeat;
	background-size: cover;
	height:400px;
	text-align:center;

	h2 {
		color:#fff;
		padding-top:10rem;
	}
}