nav#main-navigation {
    position:fixed;
    z-index:9;
    top:0;
    left:0;
    width:100%;
    padding:0.75rem 0;
    background-color:$white;
    transition:box-shadow 300ms ease-in-out;
    box-shadow:0 1px 2px rgba(0,0,0,0.5);

    @include breakpoint(medium) {
        box-shadow:none;

        &.has-shadow {
            box-shadow:0 1px 2px rgba(0,0,0,0.5);
        }
    }

    #main-nav > ul {
        margin:auto;
        width:100%;
        flex-direction:column;

        @include breakpoint(medium) {
            flex-direction:row;
            justify-content:center;
        }

        > li > a {
            color:$black;
            font-size:1.15rem;
            font-weight:normal;
            padding-left:0;
            padding-right:0;

            @include breakpoint(medium) {
                padding-top:0.15rem;
                padding-bottom:0.15rem;
                padding-left:0.75rem;
                padding-right:0.75rem;
                border-right:1px solid #ddd;
            }

            @include breakpoint(large) {
                padding-left:1.1rem;
                padding-right:1.1rem;
            }
        }
    }

    #language-selection {
        min-width:auto;
        width:calc(100% + 1px);
        left:-1px;

        @include breakpoint(medium) {
            border-top: 0;
        }

        li {
            font-size:1.15rem;
            a {
                color:$black;
                &:hover {
                    color:#000;
                }

                @include breakpoint(medium) {
                    padding-top:0.5rem;
                    padding-bottom:0.5rem;
                    padding-left:0.75rem;
                    padding-right:0.75rem;
                }

                @include breakpoint(large) {
                    padding-left:1.1rem;
                    padding-right:1.1rem;
                }
            }
        }
    }
}