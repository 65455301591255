section.info-products {
  padding-top:5rem;
  padding-bottom:5rem;

  img {
    width:100%;
    max-width:250px;
  }

  h3 {
    text-align:center;

    @include breakpoint(large) {
      text-align:left;
    }
  }

  figcaption {
    margin-bottom:2rem;

    @include breakpoint(large) {
      margin-bottom:0;
    }
  }
}