section.info-history {
  background-color:#f2f2f2;
  padding-top:5rem;
  padding-bottom:5rem;

  h3 {
    text-align:center;

    @include breakpoint(large) {
      text-align:left;
    }
  }

  figure {
    @include breakpoint(large) {
      text-align:left;
    }
  }

  figcaption {
    margin-bottom:2rem;

    @include breakpoint(large) {
      margin-bottom:0;
    }
  }
}