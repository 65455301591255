section.products {
    padding-top:6rem;
    padding-bottom:4.5rem;

    h2 {
      margin-bottom:1.5rem;
      color:$aksu-brown;

      @include breakpoint(medium) {
          margin-bottom:2.5rem;
      }
    }

    .button {
      display:flex;
      align-items:center;
      justify-content:center;
      text-decoration:none;

      span {
        text-decoration:underline;
      }

      i {
        font-size:2rem;
        vertical-align:middle;
        padding-left:0.75rem;
      }
    }
}