section.info-details {
  padding-top:5rem;
  padding-bottom:6rem;

  .product-container {
    padding-bottom:1.5rem;
  }

  .product-image img {
    max-width:100px;

    @include breakpoint(medium) {
      max-width:175px;
    }
  }

  .product-description {
    margin-top:0.5rem;

    > b {
      margin-bottom:0.5rem;
      display:block;
    }

    @include breakpoint(medium) {
      margin-top:0;

      > b {
        margin-bottom:0;
      }
    }
  }

  .quality-classes {
    padding-left:1.5rem;
  }
}