header {
  margin-top:75px;
  margin-bottom:6rem;

	a {
		color:#000;
		font-size:0.9rem;
		font-weight:bold;
		line-height:1.1;
	}

  .company-logo {
      margin-top:3.5rem;
      margin-bottom:1.5rem;
  }
}

#page-info {
  header {
    margin-bottom:0;

    .company-logo {
      margin-top:0;
      margin-bottom:0;
      max-width:220px;
    }
  }
}