section.about {
	margin-top:0.3rem;
  background-color:$aksu-green;
  color:#fff;
  padding-top:4rem;
  padding-bottom:4.5rem;

  img {
    margin:1.5rem;
    max-height:70px;
  }

  h5 {
    margin-bottom:0.4rem;
  }

  b {
    font-size:0.9em;
  }
}