section.services {
  padding-top:4.5rem;
  padding-bottom:4.5rem;
  background-color:#f2f2f2;

  h2 {
    margin-bottom:1.5rem;
    color:$aksu-green;
  }

  img.icon {
    margin:1.5rem;
    max-height:70px;
  }
}