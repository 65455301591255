footer {
    position:relative;
    background-color:$aksu-green;
	padding:5rem 0 3.5rem;
	position:relative;
    color:#fff;

    a {
        color:#fff;
    }

    h2 {
        margin-top:1.5rem;
        margin-bottom:4rem;
    }

    .column-menu {
        padding-right:5%;
        ul li a {
            line-height: 1.75rem;
        }
    }

    .column-address {
        margin-bottom:1.35rem;
        line-height: 1.75rem;

        @include breakpoint(large) {
            margin-bottom:0;
            padding-left:5%;
            padding-right:5%;
            border-left:2px dashed #fff;
            border-right:2px dashed #fff;
        }
    }

    .column-disclaimer {
        font-size:0.9rem;

        p {
            line-height:1;
            margin-bottom:1rem;
        }

        i {
            font-size:1.15rem;
        }
    }

    .copy-info {
        margin-top:0.5rem;
    }
}