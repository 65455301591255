section.consulting {
  font-style:italic;
	background-image:url(../images/consulting.jpg);
	background-repeat:no-repeat;
	background-size: cover;
	height:400px;
  margin-top:6px;

	h2 {
    color:#fff;
    padding-top:10rem;
	}

  @include breakpoint(large) {
      text-align:left;
  }
}