*[data-scroll-target] {
    cursor:pointer;
}

@each $breakpoint in $breakpoint-classes {
    @include breakpoint($breakpoint) {
        .column.#{$breakpoint}-shrink {
            flex: 0 0 auto;
            max-width: 100%;
        }
    }
}

section {
    position:relative;
}

.reveal .close-button-x {
    position:absolute;
    top:1rem;
    right:1rem;
    font-size:3rem;
    cursor:pointer;
}

.full-width {
	width:100% !important;
    max-width:initial !important;
}

.arrow-icon {
    position:absolute;
    z-index:3;
    left:50%;
    top:0;
    display:inline-block;
    background-color:$aksu-green;
    padding:0.65rem;
    border-radius:100px;
    border:6px solid #fff;
    transform:translate(-50%, -50%);

    i {
        color:#fff;
        line-height:1.5rem;
        width:1.5rem;
        font-size:1.5rem;
        text-align:center;
        vertical-align:middle;
    }

    &.white {
        border-color:#fff;
        background-color:#fff;

        i {
            color:$aksu-green;
        }
    }
}

.button {
    font-weight:bold;

    &.no-radius {
        border-radius:0;
    }

    &.underlined {
        text-decoration:underline;
    }
}

figcaption {
    margin-top:0.25rem;
    color:$primary-color;
    font-weight:bold;
    font-size:0.8rem;
    display:inline-block;
}

.dash-style-list {
    margin:0.5rem 0;

    .shrink {
        padding-left:1rem;
        padding-right:5px;
        font-weight:bold;
    }
}

#scroll-target-start {
    position:absolute;
    top:0;
}