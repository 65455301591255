section.contact {
    padding-top:2rem;
    padding-left:$global-padding;
    padding-right:$global-padding;
    max-width:1250px;
    margin:auto;

    @include breakpoint(large) {
        padding-top:4.5rem;
    }

    h2 {
        margin-bottom:6.5rem;

        @include breakpoint(large) {
            margin-bottom:8.5rem;
        }
    }

	h4 {
		@include breakpoint(small) {
			font-size:0.9rem;
			padding-top:1.5rem;
		}

		@include breakpoint(medium) {
			font-size:0.9rem;
			padding-top:0.5rem;
		}

		@include breakpoint(large) {
			font-size:1.1rem;
		}
	}

	img {
		max-width:100%;
	}

    .large-order-1 {
        position:relative;
        padding-bottom:3rem;
        overflow:hidden;

        > img {
            @include breakpoint(medium) {
                position: absolute;
                max-width:350px;
                z-index:1;
                top:-20px;
                left:0;
            }

            @include breakpoint(large) {
                max-width:auto;
            }
        }


        .text-content {
            position:relative;
            z-index:2;
            margin-top:1rem;
            padding-left:1rem;
            padding-right:1rem;
            font-size:1rem;
            text-align:center;

            @include breakpoint(medium) {
                margin-top:0;
                padding-left:42%;
                font-size:1rem;
                padding-right:2.5rem;
            }
        }
    }

    .sticker-card {
        background-color:#fff;
		border-radius:$global-radius;
		color:#000;
		height:100%;
		padding-bottom:3rem;
		position:relative;
        text-align:center;

        @include breakpoint(large) {
            padding-bottom:5rem;
        }

        h3 {
            font-size:1.5rem;
        }

		a {
			color:#000;
		}

        .fa-at {
            color:$aksu-green;
            transform:rotate(-45deg);
        }

        .fa-phone {
            color:$aksu-green;
        }

        .fa {
            font-size:3rem;
            text-align:center;
        }

        > div {
			border-left:none;
			height:100%;

            @include breakpoint(large) {
				border-left:5px dashed $aksu-green;
				border-top:none;
				padding-left:3.5rem;
            }
        }

        #contact-form {
            input {
                margin-bottom:0.5rem;

                &#user-email {
                    margin-bottom:0;
                }
            }

            textarea {
                height:100%;
            }

            #submit-contact-form {
                margin-top:0.5rem;
                margin-bottom:0;
                width:100%;
            }
        }
    }

    .address-card {
        > div {
            padding-top:3.5rem;
        }

        a {
            color:#000;
        }
    }

	.signature {
		max-width:75%;
		padding-bottom:4rem;

		@include breakpoint(medium) {
			max-width:50%;
		}
	}

    #contact-form {
        position:relative;
        z-index:2;

        .form-column {
            padding-right:0.5rem;
        }
    }

    #uic-sending-overlay {
        display:none;
    }

    #contact-success-message {
        display:none;
    }
}