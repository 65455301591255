section.certificates {
    padding-top:1.5rem;
    padding-bottom:7.5rem;

    h2 {
      margin-bottom:1.5rem;
      color:$aksu-brown;

      @include breakpoint(medium) {
          margin-bottom:2.5rem;
      }
    }

    #certificates-logos {
      margin-top:2.5rem;

      .cell {
        text-align:center;

        img {
          max-height:130px;
          margin-bottom:1.5rem;
        }
      }
    }
}